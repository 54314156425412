export function validateEmail(email) {
    if(!email) return false;
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(email);
}

export function validatePhone(phone) {
    if(!phone) return false;
    phone = phone.replace(/[\s-\(\)]/g,'');
    if(phone.startsWith("+")) {
        return (new RegExp(/^\+[0-9]{11}$/i)).test(phone);
    }
    return (new RegExp(/^[0-9]{9}$/i)).test(phone);
}

export function isNumeric(str) {
    if (typeof str === "number") return true;
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str))
}